import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import './Header.css'

const Header = ({ siteTitle }) => (
<div>
  <div className="Header">
    <div className="HeaderGroup">
      <div className="HeaderLogo">
        <Link to="/">Lori | UX Designer</Link>
      </div>
      <div className="HeaderNav">
        <Link to="/about">About</Link>
      </div>
      <div className="HeaderNav">
        <Link to="/">Work</Link>
      </div>
    </div>
  </div>
</div>
)

/**
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
*/

export default Header
