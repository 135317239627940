import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"


import Layout from "../components/layout"
import ProjectHero from "../components/ProjectHero";
import "./Article.css"



export default props => {
  return (
    <Layout>

    <div className="ProjectHero">
      <ProjectHero
        title="UI design exercise"
        intro="I’m currently taking on a 100 day challenge to craft my UI and visual design skills :)"
        client="N/A"
        team="Solo work, beginning in July 2019"
        myrole="UI design"
        />
    </div>

    <Img fluid={props.data.hero.childImageSharp.fluid} />

    <p><br /><br /></p>
    <div className="FullImg">
      <Img fluid={props.data.booking.childImageSharp.fluid} />
    </div>

    <p><br /><br /></p>
    <div className="FullImg">
      <Img fluid={props.data.usOpen.childImageSharp.fluid} />
      <Img fluid={props.data.usOpen2.childImageSharp.fluid} />
    </div>


    <p><br /><br /></p>
    <div className="FullImg">
      <Img fluid={props.data.train.childImageSharp.fluid} />
    </div>

    <p><br /><br /></p>
    <div className="RegImg">
      <Img fluid={props.data.zara.childImageSharp.fluid} />
    </div>

    <p><br /><br /></p>
    <div className="RegImg">
      <Img fluid={props.data.starbucks.childImageSharp.fluid} />
    </div>

    <p><br /><br /></p>
    <div className="RegImg">
      <Img fluid={props.data.drift.childImageSharp.fluid} />
    </div>


  </Layout>

  )
}

export const query = graphql`
  query {
    booking: file(relativePath: { eq: "ui-exercise/booking.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    usOpen: file(relativePath: { eq: "ui-exercise/us-open.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    usOpen2: file(relativePath: { eq: "ui-exercise/us-open-2.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }


    train: file(relativePath: { eq: "ui-exercise/12306.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    drift: file(relativePath: { eq: "ui-exercise/drift.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    zara: file(relativePath: { eq: "ui-exercise/zara.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    starbucks: file(relativePath: { eq: "ui-exercise/starbucks.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    hero: file(relativePath: { eq: "ui-exercise/hero.png" }){
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }


  }
`
